import React, { useState } from "react";
import "../Header/Header.css";
import Logo from "../../assets/logo.png";
import { Link, useLocation } from "react-router-dom";

function Navbar() {
    const [active, setActive] = useState("nav__menu");
    const [icon, setIcon] = useState("nav__toggler");
    const location = useLocation();

    const navToggle = () => {
        setActive(active === "nav__menu" ? "nav__menu nav__active" : "nav__menu");
        setIcon(icon === "nav__toggler" ? "nav__toggler toggle" : "nav__toggler");
    };

    const closeMenu = () => {
        setActive("nav__menu");
        setIcon("nav__toggler");
    };

    return (
        <header>
            <div className="container">
                <div className="nav">
                    <Link to="/" className="nav__brand" title="Homely Retreats">
                        <img src={Logo} alt="logo" onClick={closeMenu} />
                    </Link>
                    <ul className={active}>
                        <li className={`nav__item ${location.pathname === '/' ? 'active' : ''}`}>
                            <Link to="/" className="nav__link" onClick={closeMenu}>
                                Home
                            </Link>
                        </li>
                        <li className={`nav__item ${location.pathname === '/about' ? 'active' : ''}`}>
                            <Link to="/about" className="nav__link" onClick={closeMenu}>
                                About
                            </Link>
                        </li>
                        <li className={`nav__item ${location.pathname === '/packages' ? 'active' : ''}`}>
                            <Link to="/packages" className="nav__link" onClick={closeMenu}>
                                Packages
                            </Link>
                        </li>
                        <li className={`nav__item ${location.pathname === '/blog' ? 'active' : ''}`}>
                            <Link to="/blog" className="nav__link" onClick={closeMenu}>
                                Blog
                            </Link>
                        </li>
                        <a href="tel:9541786819" className="call-btn d-flex place-center">Call Now</a>
                    </ul>

                    <div onClick={navToggle} className={icon}>
                        <div className="line1"></div>
                        <div className="line2"></div>
                        <div className="line3"></div>
                    </div>
                </div>
            </div>
        </header>
    );
}


export default Navbar;
