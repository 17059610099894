import React, { useEffect, useState } from "react";
import "../Blog/Blog.css";
import { createClient } from '@sanity/client';
import { PortableText } from '@portabletext/react';

// Initialize the Sanity client using createClient
const client = createClient({
    projectId: "71aq13n0", // Replace with your Sanity project ID
    dataset: 'production', // Or your dataset name
    useCdn: true,
    apiVersion: '2021-08-31', // use a UTC date string
});

const Blog = () => {
    const [blogs, setBlogs] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [blogsPerPage] = useState(3); // Adjust this value to control items per page
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        document.title = "Homely Retreats | Blogs";
        const fetchData = async () => {
            try {
                const query = `*[_type == "blog"]{_id, Heading, Author, Date, Description, Image{asset->{_id, url}}}`; // Adjust the query as needed
                const result = await client.fetch(query);
                setBlogs(result);
                setIsLoading(false);
                console.log(result);
            } catch (error) {
                console.error(error);
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    // Get current page blogs
    const indexOfLastBlog = currentPage * blogsPerPage;
    const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
    const currentBlogs = blogs.slice(indexOfFirstBlog, indexOfLastBlog);

    // Change page handler
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <>
            <main>
                <h3 className="title">Book Your Hut</h3>
                {isLoading ? (
                    <p className="Loading">Loading...</p>
                ) : (
                    currentBlogs.map((element) => (
                        <section id="blog-section" key={element?._id} className="my-5">
                            <div className="container containerblog">
                                {element?.Image?.asset?.url && (
                                    <div className="imagecontainer">
                                        <img
                                            src={element?.Image?.asset?.url}
                                            alt="Blog"
                                            className="BlogImage"
                                        />
                                    </div>
                                )}
                                <h1>{element?.Heading}</h1>
                                <div className="box d-flex a-center">
                                    <h5>{element?.Author}</h5>
                                </div>
                                <div className="description">
                                    <PortableText value={element?.Description} />
                                </div>
                            </div>
                        </section>
                    ))
                )}
            </main>

            <div className="container btns d-flex justify-content-between my-5">
                <button className="btn btn-lg btn-outline-success" onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}>
                    Previous
                </button>
                {Math.ceil(blogs.length / blogsPerPage) > 1 && (
                    <button className="btn btn-lg btn-outline-success"
                        onClick={() => paginate(currentPage + 1)}
                        disabled={currentPage === Math.ceil(blogs.length / blogsPerPage)}
                    >
                        Next
                    </button>
                )}
            </div>
        </>
    );
};

export default Blog;
