import React from 'react'
import "../Book-Choice/BookChoice.css"
import Slider from "react-slick";
import Image1 from "../../assets/1.jpg"
import Image2 from "../../assets/Nishat.jpg"
import Image3 from "../../assets/Mansar-Lake.png"
import Image4 from "../../assets/Parimahal.jpg"
import Image5 from "../../assets/Hazratbal.jpg"
import Image6 from "../../assets/chasmeshahi.jpg"
import Image7 from "../../assets/kheerbawani.jpg"
import Image8 from "../../assets/manasbal.jpg"
import Image9 from "../../assets/Sonamarg.jpg"
import Image10 from "../../assets/naranag.jpg"


import whatssapp from "../../assets/whatssapp.svg"
import { Link } from 'react-router-dom';

const BookChoice = () => {
    var settings = {
        slidesToShow: 1,
        slideToScroll: 3,
        variableWidth: true,
        dots: false,
        arrows: false,
        draggable: true,
        autoplay: false,
        infinite: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    slideToScroll: 1,

                }
            },
            {
                breakpoint: 567,
                settings: {
                    variableWidth: false,
                    slidesToShow: 1,
                    slideToScroll: 1,

                }
            }
        ]
    };

    return (
        <section id="booking-section">
            <h4>Places Near Us</h4>
            <p>Place within our Vicinity</p>

            <ul className="booking-box">
                <Slider {...settings}>
                    <li className='inner-box'>
                        <img src={Image1} alt="Booking Image" />
                        <div className='d-flex a-center j-between g-20 box'>
                            <h5>Shalimar Bagh </h5>
                            <Link to="https://www.google.com/maps/dir//Chinar+chowk,+Shalimar,+Srinagar,+Jammu+and+Kashmir+191121/@34.1494973,74.7905234,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x38e18427e6eb2bad:0xfd1fee426b5f903d!2m2!1d74.8729246!2d34.1495248?entry=ttu" className='link' target='_blank'>View</Link>
                        </div>
                    </li>
                    <li className='inner-box'>
                        <img src={Image2} alt="Booking Image" />
                        <div className='d-flex a-center j-between g-20 box'>
                            <h5>Nishat Bagh</h5>
                            <Link to="https://www.google.com/maps/place/Nishat+Garden/@34.1245569,74.8787691,15z/data=!4m14!1m7!3m6!1s0x38e1871edfa24f95:0x163329e00253085!2sNishat+Garden!8m2!3d34.1245569!4d74.8787691!16s%2Fg%2F11f766d9hq!3m5!1s0x38e1871edfa24f95:0x163329e00253085!8m2!3d34.1245569!4d74.8787691!16s%2Fg%2F11f766d9hq?entry=ttu" className='link' target='_blank'>View</Link>
                        </div>
                    </li>
                    <li className='inner-box'>
                        <img src={Image3} alt="Booking Image" />
                        <div className='d-flex a-center j-between g-20 box'>
                            <h5>Dal lake</h5>
                            <Link to="https://www.google.com/maps/place/Dal+Lake/@34.1137529,74.8135448,13z/data=!3m1!4b1!4m6!3m5!1s0x38e185c268876847:0xbf6def9295995179!8m2!3d34.1105857!4d74.8682569!16zL20vMDZkNmJk?entry=ttu" className='link' target='_blank'>View</Link>
                        </div>
                    </li>
                    <li className='inner-box'>
                        <img src={Image4} alt="Booking Image" />
                        <div className='d-flex a-center j-between g-20 box'>
                            <h5>Parimahal</h5>
                            <Link to="https://www.google.com/maps/place/Pari+Mahal/@34.0811983,74.8745771,17z/data=!3m1!4b1!4m6!3m5!1s0x38e188b3a786b323:0x2177c244cb41bcc3!8m2!3d34.0811939!4d74.877152!16s%2Fm%2F0jl1tpw?entry=ttu" className='link'>View</Link>
                        </div>
                    </li>

                    <li className='inner-box'>
                        <img src={Image5} alt="Booking Image" />
                        <div className='d-flex a-center j-between g-20 box'>
                            <h5>Hazratbal Shrine</h5>
                            <Link to="https://www.google.com/maps/place/Dargah+Hazratbal+Shrine/@34.1289373,74.839916,17z/data=!3m1!4b1!4m6!3m5!1s0x38e185bc7a3fbbd9:0x9b6cf66822475e36!8m2!3d34.1289329!4d74.8424909!16zL20vMGM2bnpz?entry=ttu" className='link'>View</Link>
                        </div>
                    </li>
                    
                    <li className='inner-box'>
                        <img src={Image6} alt="Booking Image" />
                        <div className='d-flex a-center j-between g-20 box'>
                            <h5>Chasme Shahi</h5>
                            <Link to="https://www.google.com/maps/place/Cheshma+Shahi+Garden/@34.0860336,74.885194,17z/data=!3m1!4b1!4m6!3m5!1s0x38e188a54cf4f533:0x41e1286e1bd7139!8m2!3d34.0860292!4d74.8877689!16s%2Fm%2F027082n?entry=ttu" className='link'>View</Link>
                        </div>
                    </li>

                    <li className='inner-box'>
                        <img src={Image7} alt="Booking Image" />
                        <div className='d-flex a-center j-between g-20 box'>
                            <h5>Kheer Bhawani Temple</h5>
                            <Link to="https://www.google.com/maps/place/Sri+Kheer+Bhawani+Durga+Temple/@34.2211918,74.7282372,17z/data=!3m1!4b1!4m6!3m5!1s0x38e19da6fe2d0dfb:0xcbd5d2afee07de5d!8m2!3d34.2211874!4d74.7308121!16s%2Fm%2F026xl0q?entry=ttu" className='link'>View</Link>
                        </div>
                    </li>

                    <li className='inner-box'>
                        <img src={Image8} alt="Booking Image" />
                        <div className='d-flex a-center j-between g-20 box'>
                            <h5>Manasbal Lake</h5>
                            <Link to="https://www.google.com/maps/place/Manasbal+Lake/@34.2500507,74.659996,15z/data=!3m1!4b1!4m6!3m5!1s0x38e175f1690e4237:0xbb9ef622d7c5597f!8m2!3d34.2471776!4d74.6707823!16s%2Fm%2F04n0yy5?entry=ttu" className='link'>View</Link>
                        </div>


                    </li>

                    <li className='inner-box'>
                        <img src={Image9} alt="Booking Image" />
                        <div className='d-flex a-center j-between g-20 box'>
                            <h5>Sonamarg</h5>
                            <Link to="https://www.google.com/maps/place/Sonamarg/@34.3028446,75.2865717,16z/data=!3m1!4b1!4m6!3m5!1s0x38e234e197d78061:0x5ccdc93dd4660335!8m2!3d34.3031616!4d75.293111!16s%2Fm%2F03cgp27?entry=ttu" className='link'>View</Link>
                        </div>
                    </li>

                    <li className='inner-box'>
                        <img src={Image10} alt="Booking Image" />
                        <div className='d-flex a-center j-between g-20 box'>
                            <h5>Naranag</h5>
                            <Link to="https://www.google.com/maps/place/Naranaag/@34.3545377,74.9760189,17z/data=!3m1!4b1!4m6!3m5!1s0x38e3d5f1d4294d43:0xc1ae7bf7efe3ced9!8m2!3d34.3545333!4d74.9785938!16s%2Fg%2F12hlgydp6?entry=ttu"  className='link' >View</Link>
                        </div>
                    </li>

                </Slider>
            </ul>
            <Link to="https://wa.me/9541786819" className='d-flex place-center g-20'>   <img src={whatssapp} alt="Homely Retreats" />Whatsapp Us</Link>

        </section>
    )
}

export default BookChoice