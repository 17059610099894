import React, { useState,useEffect} from 'react';
import '../AddData/AddData.css';
import axios from 'axios';

const AddData = () => {
    useEffect(() => {
        document.title = "Homely Retreats | Admin ";
      }, []);
    const [activeTab, setActiveTab] = useState('blogs');

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };
    
    // const handleChange = (e) => {
    //     const { name, value, files } = e.target;
    //     setFormData({
    //         ...formData,
    //         [name]: files ? files[0] : value
    //     });
    // };
const [Heading,setHeading] = useState("");
const [Description,setDesc] = useState("");
const [Author,setAuth] = useState("");
const [Date,setDate] = useState("");
const [Image,setImage] = useState('');
//   console.log(Image);
const handleChangeHeading = (e)=>{
  setHeading(e.target.value);
    // console.log(Heading); 
}
const handleChangeDescription = (e)=>{
    setDesc(e.target.value);
    //   console.log(Desc); 
  }  
  const handleChangeAuthor = (e)=>{
    setAuth(e.target.value);
    //   console.log(Author); 
  } 
  const handleChangeDate = (e)=>{
    setDate(e.target.value);
    //   console.log(Date); 
  } 



    const handleSubmit = () => {
       const formData = new FormData();
       formData.append('Heading',Heading);        
       formData.append('Description',Description);        
       formData.append('Author',Author);        
       formData.append('Date',Date);        
       formData.append('Image',Image);        
       axios.post('http://localhost:8080/api/blogs/addblogs',
       formData
       
       ).then((res)=>{
        alert('Blog Added Sucessfully')
        console.log(res)
       }).catch((err)=>{
        console.log(err);
       })
    };
    // const [priceData, setPriceData] = useState({
    //     standard: { perNight: '', weekly: '', monthly: '' },
    //     extendedStay: { perNight: '', weekly: '', monthly: '' },
    //     special: { perNight: '', weekly: '', monthly: '' }
    // });
    // const handlePriceChange = (e, packageType, field) => {
    //     const { value } = e.target;
    //     setPriceData({
    //         ...priceData,
    //         [packageType]: {
    //             ...priceData[packageType],
    //             [field]: value
    //         }
    //     });
    // };
    const [standardPerNight, setStandardPerNight] = useState('');
    const [standardWeekly, setStandardWeekly] = useState('');
    const [standardMonthly, setStandardMonthly] = useState('');
    const [ExtendedPerNight, setExtendedPerNight] = useState('');
    const [ExtendedWeekly, setExtendedWeekly] = useState('');
    const [ExtendedMonthly, setExtendedMonthly] = useState('');
    const [SpecialPerNight, setSpecialPerNight] = useState('');
    const [SpecialWeekly, setSpecialWeekly] = useState('');
    const [SpecialMonthly, setSpecialMonthly] = useState('');
     
    // functions defined for corressponding input fields:

let handleStandardPerNight=(e)=>{
    setStandardPerNight(e.target.value)
   console.log(standardPerNight);

}
let handleStandardweekly=(e)=>{
    setStandardWeekly(e.target.value)
    console.log(standardWeekly);
 
 }
 let handleStandardMonthly=(e)=>{
    setStandardMonthly(e.target.value)
    console.log(standardMonthly);
 }

 let handleExtendedPerNight =(e)=>{
    setExtendedPerNight(e.target.value)
    console.log(ExtendedPerNight);
 }
 let HandleExtendedWeekly=(e)=>{
    setExtendedWeekly(e.target.value)
    console.log(ExtendedWeekly);
 
 }
 let HandleExtendedMonthly=(e)=>{
    setExtendedMonthly(e.target.value)
    console.log(ExtendedMonthly);
 
 }
 let HandleSpecialPerNight=(e)=>{
     setSpecialPerNight(e.target.value)
    console.log(SpecialPerNight);
     
 }
 let HandleSpecialWeekly=(e)=>{
    setSpecialWeekly(e.target.value)
    console.log(SpecialWeekly);

 }
 let HandleSpecialMonthly=(e)=>{
    setSpecialMonthly(e.target.value)
    console.log(SpecialMonthly);
 
 }

    const handlePriceSubmit = () => {
        // console.log(priceData);
        const data = {
            hotelName: 'Your Hotel Name', 
            StandardPrice: {
              perNight: standardPerNight,
              weekly: standardWeekly,
              monthly: standardMonthly,
            },
            ExtendedStay: {
              perNight: ExtendedPerNight,
              weekly: ExtendedWeekly,
              monthly: ExtendedMonthly,
            },
            Special: {
              perNight: SpecialPerNight,
              weekly: SpecialWeekly,
              monthly: SpecialMonthly,
            }

        }
        axios.post("http://localhost:8080/api/packages/addpackage",data).then((res)=>{
            alert("Package Added Successfully!");
            console.log(res);
        }).catch((err)=>{
            console.log("This is a vital",err);
        })


    };
    return (
        <>
            <section className="tabs" id='add-data-section'>
                <div className="container d-flex g-60 place-center">
                    <div
                        className={`tab ${activeTab === 'blogs' ? 'active' : ''}`}
                        onClick={() => handleTabClick('blogs')}
                    >
                        <h3>
                            Blog
                        </h3>
                    </div>
                    <div
                        className={`tab ${activeTab === 'price' ? 'active' : ''}`}
                        onClick={() => handleTabClick('price')}
                    >
                        <h3>
                            Price
                        </h3>
                    </div>
                </div>

            </section>

            <div className="tab-content">
                <div className="container">
                    {activeTab === 'blogs' && (
                        <div className='blog-content'>
                            <h3>Blog</h3>
                            <div className="add-blog-data">
                                <div className="input-box">
                                    <label htmlFor="heading">Heading:</label>
                                    <input type="text" id="heading" name="Heading" value={Heading}  onChange={handleChangeHeading} />
                                </div>
                                <div className="input-box">
                                    <label htmlFor="image">Image:</label>
                                    <input type="file" id="image" name="Image" accept="image/*" onChange={(e)=>{setImage(e.target.files[0])}} />
                                </div>
                                <div className="input-box">
                                    <label htmlFor="description">Description:</label>
                                    <textarea id="description" name="description" value={Description} onChange={handleChangeDescription}></textarea>
                                </div>
                                <div className="input-box">
                                    <label htmlFor="date">Date:</label>
                                    <input type="date" id="date" name="date" value={Date} onChange={handleChangeDate} />
                                </div>
                                <div className="input-box">
                                    <label htmlFor="author">Author:</label>
                                    <input type="text" id="author" name="Author" value={Author} onChange={handleChangeAuthor} />
                                </div>

                                <button onClick={handleSubmit}>Publish</button>
                            </div>
                        </div>
                    )}

                    {activeTab === 'price' && (
                        <div className='price-content'>
                            <h3>Price</h3>
                            <div className="add-price-data">
                                <table cellSpacing={10}>
                                    <thead>
                                        <tr>
                                            <th>Package</th>
                                            <th>Per Night Rates</th>
                                            <th>Weekly</th>
                                            <th>Monthly Rates</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Standard</td>
                                            <td><input type="text" value={standardPerNight} onChange={handleStandardPerNight}/></td>
                                            <td><input type="text" value={standardWeekly} onChange={handleStandardweekly}/></td>
                                            <td><input type="text" value={standardMonthly} onChange={handleStandardMonthly}/></td>
                                        </tr>
                                        <tr>
                                            <td>Extended Stay</td>
                                            <td><input type="text" value={ExtendedPerNight} 
                                            onChange={handleExtendedPerNight} /></td>
                                            <td><input type="text" value={ExtendedWeekly} onChange={HandleExtendedWeekly} /></td>
                                            <td><input type="text"value={ExtendedMonthly} onChange={HandleExtendedMonthly} /></td>
                                        </tr>
                                        <tr>
                                            <td>Special</td>
                                            <td><input type="text"value={SpecialPerNight} onChange={HandleSpecialPerNight} /></td>
                                            <td><input type="text" value={SpecialWeekly} onChange={HandleSpecialWeekly} /></td>
                                            <td><input type="text" value={SpecialMonthly} onChange={HandleSpecialMonthly} /></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <button onClick={handlePriceSubmit}>Publish</button>
                            </div>
                        </div>
                    )}
                </div>

            </div >
        </>
    );
};

export default AddData;
