import React from 'react'
import "../Book-Choice/BookChoice.css"
import Slider from "react-slick";
import Image1 from "../../assets/1.jpg"
import Image2 from "../../assets/Nishat.jpg"
import Image3 from "../../assets/Mansar-Lake.png"
import Image4 from "../../assets/Parimahal.jpg"
import Image5 from "../../assets/Hazratbal.jpg"
import Image6 from "../../assets/chasmeshahi.jpg"
import Image7 from "../../assets/kheerbawani.jpg"
import Image8 from "../../assets/manasbal.jpg"
import Image9 from "../../assets/Sonamarg.jpg"
import Image10 from "../../assets/naranag.jpg"


import whatssapp from "../../assets/whatssapp.svg"
import { Link } from 'react-router-dom';

const BookChoice = () => {


    return (
        <section id="booking-section">
         
            <Link to="https://wa.me/9541786819" className='d-flex place-center g-20'>   <img src={whatssapp} alt="Homely Retreats" />Whatsapp Us</Link>

        </section>
    )
}

export default BookChoice