import React from 'react';
import { Link } from "react-router-dom";

const Terms = (props) => {
    return (
        <>
            <div className="TermsDiv"id="Terms" style={{padding:"0px", margin:"15vh 20px 20px 20px"}}>
               
            <h1>Homely Retreats Terms of Use</h1>

<p>Welcome to Homely Retreats! These Terms of Use govern your use of the website homelyretreats.in. By accessing or using our website, you agree to comply with these terms. Please read them carefully before proceeding.</p>

<h2>Acceptance of Terms</h2>
<p>By accessing or using our website, you agree to be bound by these Terms of Use and all applicable laws and regulations. If you do not agree with any of these terms, you are prohibited from using or accessing this site.</p>

<h2>Use License</h2>
<p>Permission is granted to temporarily download one copy of the materials (information or software) on Homely Retreats' website for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license, you may not:</p>
<ul>
    <li>Modify or copy the materials;</li>
    <li>Use the materials for any commercial purpose or for any public display (commercial or non-commercial);</li>
    <li>Attempt to decompile or reverse engineer any software contained on Homely Retreats' website;</li>
    <li>Remove any copyright or other proprietary notations from the materials; or</li>
    <li>Transfer the materials to another person or "mirror" the materials on any other server.</li>
</ul>

<h2>Disclaimer</h2>
<p>The materials on Homely Retreats' website are provided on an 'as is' basis. Homely Retreats makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights. Further, Homely Retreats does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on its website or otherwise relating to such materials or on any sites linked to this site.</p>

<h2>Limitations</h2>
<p>In no event shall Homely Retreats or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use the materials on Homely Retreats' website, even if Homely Retreats or a Homely Retreats authorized representative has been notified orally or in writing of the possibility of such damage. Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to you.</p>

<h2>Accuracy of Materials</h2>
<p>The materials appearing on Homely Retreats' website could include technical, typographical, or photographic errors. Homely Retreats does not warrant that any of the materials on its website are accurate, complete, or current. Homely Retreats may make changes to the materials contained on its website at any time without notice. However, Homely Retreats does not make any commitment to update the materials.</p>

<h2>Links</h2>
<p>Homely Retreats has not reviewed all of the sites linked to its website and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by Homely Retreats of the site. Use of any such linked website is at the user's own risk.</p>

<h2>Modifications</h2>
<p>Homely Retreats may revise these terms of service for its website at any time without notice. By using this website, you are agreeing to be bound by the then current version of these terms of service.</p>

<h2>Governing Law</h2>
<p>These terms and conditions are governed by and construed in accordance with the laws of India and you irrevocably submit to the exclusive jurisdiction of the courts in Ganderbal and Srinagar districts of the Union Territory of Jammu and Kashmir.</p>

<p>By accessing or using Homely Retreats' website, you signify your acceptance of these terms. If you do not agree to these terms, please refrain from using our website. If you have any questions about these Terms of Use, please contact us on +91-1943158376 or at homelykashmir@gmail.com.</p>

                    
            </div>
        </>
    );
};

export default Terms;