import React from 'react'
import "../Our-Services/OurServices.css"
import Image1 from "../../assets/service-image1.png";
import Image2 from "../../assets/Comfo - Copy.jpg";
import Image3 from "../../assets/circle3.jpg";
import { Link } from "react-router-dom";


const OurServices = () => {
    return (
        <section id="our-services-section">
            <div className="container">
                <h4>Our Services</h4>
                <div className="services-cards d-flex place-center">
                    <div className="card d-flex-col place-center g-40">
                        <img src={Image1} alt="Homely Retreats" />
                        <h5>Homely Food</h5>
                    </div>
                    <div className="card d-flex-col place-center g-40">
                        <img src={Image2} alt="Homely Retreats" />
                        <h5>Enhanced Comfort</h5>
                    </div>
                    <div className="card d-flex-col place-center g-40">
                        <img src={Image3} alt="Homely Retreats" />
                        <h5>Heavenly View</h5>
                    </div>
                </div>
             
            </div>
        </section>
    )
}

export default OurServices