import React from 'react'
import "../Offer/Offer.css"
import Image1 from "../../assets/offer-1.png"
import Image2 from "../../assets/Comfo.jpeg"
import Image3 from "../../assets/orchardsService.webp"
import Image4 from "../../assets/scenery.webp"
import Line1 from "../../assets/line-1.svg"
import Line2 from "../../assets/line-2.svg"
import Line3 from "../../assets/line-3.svg"
import Line4 from "../../assets/line-4.svg"

const Offer = () => {
    return (
        <>
            <section id="our-offer-section">
                <div className="container">
                    <h4>We Offer</h4>
                    <div className="inner-row d-flex a-center p-relative">
                        <img src={Image1} alt="Homely Retreats" className='main-img' />
                        <div className="box">
                            <h5>Homely Food</h5>
                            <p>At the Homely ‘Villa Cottage’, we understand the importance of feeling at home, especially when it comes to the food you eat. That's why we've crafted an experience that brings the comfort and warmth of home to every meal.</p>
                        </div>
                        <img src={Line1} alt="line" className='p-absolute line-1'  />
                    
                    </div>
                    <div className="inner-row d-flex place-center p-relative"  >
                       
                        <img src={Image2} alt="Homely Retreats" className='main-img' />
                        <div className="box"   >
    
                            <h5>Enhanced Comfort</h5>
                            <p>Rejuvenate your body and mind with Yoga: Indulge in morning relaxation sessions at our dedicated space for Yoga while facing mountainous Range in the east. Complimentary Yoga Mats are available offered as a gesture of appreciation.</p>
                        </div>
                        <img src={Line2} alt="line" className='p-absolute line-2' />
                    </div>
                    <div className="inner-row d-flex place-center p-relative">
                        <img src={Image3} alt="Homely Retreats" className='main-img' />
                        <div className="box">
                        
                        <h5>Orchard Bliss</h5>
                        <p>Savor the charm of our lush orchards: Stroll through rows of vibrant fruit trees, enjoying the fragrant blossoms and bountiful harvests.</p>
                        </div>
                        <img src={Line3} alt="line" className='p-absolute line-3' />
                    </div>
                    <div className="inner-row d-flex place-center p-relative">
                        <img src={Image4} alt="Homely Retreats" className='main-img' />
                        <div className="box">
                        <h5>Scenic Serenity</h5>
                        <p>Immerse yourself in the breathtaking beauty of nature: Delight in the panoramic views of the scenic landscapes from our serene viewing spots, offering unobstructed vistas of the rolling hills and lush orchards.</p>
                        </div>
                        <img src={Line4} alt="line" className='p-absolute line-4' />
                    </div>
                    <h6 className='text'>And Much More!</h6>
                </div>
            </section>
        </>
    )
}

export default Offer