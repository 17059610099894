import React, { useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import "../Footer/Footer.css"
import logo_footer from "../../assets/logo-footer.png"
import arrow from "../../assets/arrow.svg"
import fb from "../../assets/fb.svg"
import ins from "../../assets/ins.svg"
import twitter from "../../assets/twitter.svg"

const Footer = () => {
    const emailRef = useRef(null);

    const handleSubmit = (event) => {
        event.preventDefault();
        const email = emailRef.current.value;
        console.log('Email submitted:', email);
        console.log('Form submitted!');
    };
    const location = useLocation();
    const showDiv = location.pathname === '/blog' || location.pathname === '/';
    return (
        <>
            <footer className='p-relative'>
                {showDiv && (
                    <div className="upper-box p-absolute d-flex a-center j-between">
                        <h5>Call For BOOKING</h5>
                        <Link to="/packages" className='d-flex place-center'>Book Now</Link>
                    </div>
                )}
                <div className="container d-flex  j-between">
                    <div className="logo-box">
                        <img src={logo_footer} alt="Logo" />
                        <div className="social-links d-flex place-center g-40">
                            <a href="#" target='_blank'>
                                <img src={fb} alt="Facebook" />
                            </a>
                            <a href="#" target='_blank'>
                                <img src={ins} alt="Instagram" />
                            </a>
                            <a href="#" target='_blank'>
                                <img src={twitter} alt="Twitter" />
                            </a>
                        </div>
                    </div>
                    <div className="links-box">
                        <h5>Quick Links</h5>
                        <ul>
                            <li>
                                <Link to="/">
                                    Home
                                </Link>
                            </li>
                            <li>
                                <Link to="/about">
                                    About
                                </Link>

                            </li>
                            <li>
                                <Link to="/packages">
                                    Packages
                                </Link>
                            </li>
                            <li>
                                <Link to="/blog">
                                    Blog
                                </Link>
                            </li>
                          
                        </ul>
                    </div>

                    <div className="contact-box">
                        <h5>Contact Us</h5>
                        <ul>
                        <li><a href="tel:+911943158376">+91-1943158376</a></li>
                        <li className='fs-5'><a href="mailto:reservations@homelyretreats.in">reservations@homelyretreats.in</a></li>
                            <li><a href="#">Buserbugh, Alasteng Link Road, Gulab Bagh, Srinagar</a></li>
                        </ul>
                    </div>

                    <div className="learn-more-box">
                        <h5>Learn More About Us</h5>
                        <p>Gain insights into enhancing your experience.</p>
                        <form onSubmit={handleSubmit}>
                            <div className="input-box d-flex a-center">
                                <input type="email" name="email" id="email" value="reservations@homelyretreats.in" />
                                <Link to="mailto:reservations@homelyretreats.in">
                                <div className="image-box">
                                 
                                    <input type="image" src={arrow} alt="Submit" id='submit' />
                                   
                                </div>
                                </Link>
                            </div>
                        </form>
                        <div className="box d-flex g-20">
                        <li>
                                <Link to="/Terms">
                                    Terms
                                </Link>
                            </li>
                            <li>
                            <Link to='/Policy'>Privacy Policy</Link>
                            </li>
                           
                        </div>
                    </div>




                </div>

                <div className="lower-footer">
                    <div className="container d-flex place-center">
                        <h4>Designed and Developed By <Link to="https://pixenx.com/" target='_blank'>PixenX</Link> All Rights Reserved</h4>
                    </div>
                </div>
            </footer>

        </>

    )
}

export default Footer