import React from 'react'
import "../Banner/Banner.css"
import { Link } from 'react-router-dom';


const Banner = () => {

  return (
    <>
      <section id="banner-section">
        <div className="container h-full d-flex-col j-center">
          <h1>Villa Cottage by Homely Retreats: Your Idyllic Escape</h1>
          <h3>Come Book A Stay With Us And Rejuvenate Your Mind With The Beautiful And Peaceful Atmosphere.</h3>
          <Link to="/packages" className='d-flex place-center'>Book a Stay</Link>
        </div>
      </section>

    </>
  )
}

export default Banner