import React, { useEffect, useState } from 'react';
import "../People-Talk/PeopleTalk.css";
import PeopleCards from '../People-Cards/PeopleCards';
import { createClient } from '@sanity/client';

const client = createClient({
    projectId: "71aq13n0", // Replace with your Sanity project ID
    dataset: 'production', // Or your dataset name
    useCdn: true,
    apiVersion: '2021-08-31',
});

const PeopleTalk = () => {
    const [testimonials, setTestimonials] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchTestimonials = async () => {
            try {
                const query = '*[_type == "testimonial"]{name, occupation, text,  image{asset->{_id, url}}}';
                const result = await client.fetch(query);
                setTestimonials(result);
                setIsLoading(false);
            } catch (error) {
                console.error(error);
                setIsLoading(false);
            }
        };

        fetchTestimonials();
    }, []);

    if (isLoading) return <div>Loading...</div>;

    return (
        <section id="people-section">
            <div className="container">
                <h4>People Talk About Us</h4>
                <div className="review-cards d-flex place-center">
                    {testimonials.map((testimonial, index) => (
                        <PeopleCards
                            key={index}
                            image={testimonial.image.asset.url}
                            name={testimonial.name}
                            occupation={testimonial.occupation}
                            text={testimonial.text}
                        />
                    ))}
                </div>
            </div>
        </section>
    );
};

export default PeopleTalk;
