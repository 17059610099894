import React from 'react';
import "../People-Cards/PeopleCards.css";
import Stars from "../../assets/stars.svg";

const PeopleCards = (props) => {
    return (
        <div className="people-cards d-flex-col place-center">
            <img src={props.image} alt="Homely Retreats" />
            <h3>{props.text}</h3>
            <img src={Stars} alt="Homely Retreats" className='stars' />
            <h5>{props.name}</h5>
            <h6>{props.occupation}</h6>
        </div>
    );
};

export default PeopleCards;