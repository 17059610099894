import React, { useEffect } from "react";
import "../About-Us/AboutUs.css"
import { Link } from "react-router-dom";
import BannerImage from "../../assets/BannerImage.jpeg";
import bg1 from "../../assets/bg-1.png";
import image1 from "../../assets/Down.jpeg";
import image2 from "../../assets/Up.jpeg";
import OurServices from "../../components/Our-Services/OurServices";
import Location from "../../components/Location/Location";
import About from '../../components/About/About'
import BookChoice from "../../components/Book-Choice/BookChoice";

const AboutUs = () => {
  useEffect(() => {
    document.title = "Homely Retreats | About ";
  }, []);
  return (
    <>
      <section id="about-banner-section">
        <div className="container d-flex place-center ">
          <div className="left">
            <h2>Enjoy Your Stay With Us!</h2>
            <p>Welcome to Villa Cottage by Homely Retreats, an offbeat tourist destination nestled in the heart of nature's bounty.</p>
            <Link to="/subabout" className="d-flex place-center">
              Explore More
            </Link>
          </div>
          <div className="right">
            <img src={BannerImage} alt="Homely Retreats" />
          </div>
        </div>
      </section>

      <section id="about-section-1">
        <div className="container h-full">
          <div className="row d-flex place-center h-full">
            <div className="w-lg-50 p-relative h-full d-flex place-center">
              <img src={bg1} alt="background" className="p-absolute bg" />
              <p className="p-absolute">Immerse yourself in the beauty of the surroundings with carefully crafted landscaping that complements the natural setting.</p>
            </div>
            <div className="w-lg-50">
              <img src={image1} alt="Homely Retreats" />
            </div>
          </div>
          <div className="row d-flex place-center h-full">
            <div className="w-lg-50">
              <img src={image2} alt="Homely Retreats" />
            </div>
            <div className="w-lg-50 p-relative h-full d-flex place-center">
              <img src={bg1} alt="background" className="p-absolute bg" />
              <p className="p-absolute">Our haven offers a unique experience with hutments spread across a vast expanse of apple orchards, natural flora and fauna.</p>
            </div>

          </div>
        </div>
      </section>

      <BookChoice/>
      <OurServices />
      <About/>
      <Location />
    </>
  )
}

export default AboutUs