import React, { useEffect, useState } from "react";
import "../Packages/Packages.css";

import booking1 from "../../assets/hut1.jpg";
import booking2 from "../../assets/2.png";
import booking3 from "../../assets/3.png";
import booking4 from "../../assets/4.png";
import BookingCard from "../../components/Booking-Card/BookingCard";
import Location from "../../components/Location/Location";
import axios from "axios";
import { createClient } from '@sanity/client'; 
import { Link } from "react-router-dom";

// Initialize the Sanity client using createClient
const client = createClient({
    projectId: "71aq13n0", // Replace with your Sanity project ID
    dataset: 'production', // Or your dataset name
    useCdn: true,
    apiVersion: '2021-08-31', // use a UTC date string
});

const Packages = () => {
    useEffect(() => {
        document.title = "Homely Retreats | Packages ";
    }, []);

    const [isLoading, setIsLoading] = useState(true);
    const [packages, setPackages] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const query = `*[_type == "package"]{hotelName, subtitle, image{asset->{_id, url}}, theme, tablesideheading3, features, inclusions, pricing{StandardPrice{perNight, weekly, monthly}, ExtendedStay{perNight, weekly, monthly}, Special{perNight, weekly, monthly}}, ctaText, link, Heading1, Heading2, showPricing}`; // Adjust the query as needed
                const result = await client.fetch(query);
                setPackages(result);
                setIsLoading(false);
                console.log(result);
            } catch (error) {
                console.error(error);
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    return (
        <>
            <main>
                <section id="packages-section">
                    <div className="booking-box">
                        {isLoading ? (
                            <p className="Loading">Loading...</p>
                        ) : (
                            packages.map((packages, index) => (
                                <div className="booking-card" key={index}>
                                    <img src={packages.image.asset.url} alt="Booking" className="p-absolute main-img" />
                                    <h5>{packages.hotelName}</h5>
                                    <h5 className='subtitle'>{packages.subtitle}</h5>
                                    <br />
                                    <h6>{packages.theme}</h6>
                                    <p className='text-center'></p>
                                    
                                    {packages.showPricing && (
                                        <table cellSpacing="10">
                                            <thead>
                                                <tr>
                                                    <th>{"Package"}</th>
                                                    <th>{"Per Night Rates"}</th>
                                                    <th>{"Weekly Rates"}</th>
                                                    <th>{"Monthly Rates"}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>{"Standard"}</td>
                                                    <td>{packages.pricing && packages.pricing.StandardPrice ? packages.pricing.StandardPrice.perNight : ''}</td>
                                                    <td>{packages.pricing && packages.pricing.StandardPrice ? packages.pricing.StandardPrice.weekly : ''}</td>
                                                    <td>{packages.pricing && packages.pricing.StandardPrice ? packages.pricing.StandardPrice.monthly : ''}</td>
                                                </tr>
                                                <tr>
                                                    <td>{"Extended Stay"}</td>
                                                    <td>{packages.pricing && packages.pricing.ExtendedStay ? packages.pricing.ExtendedStay.perNight : ''}</td>
                                                    <td>{packages.pricing && packages.pricing.ExtendedStay ? packages.pricing.ExtendedStay.weekly : ''}</td>
                                                    <td>{packages.pricing && packages.pricing.ExtendedStay ? packages.pricing.ExtendedStay.monthly : ''}</td>
                                                </tr>
                                                <tr>
                                                    <td>{packages.tablesideheading3}</td>
                                                    <td>{packages.pricing && packages.pricing.Special ? packages.pricing.Special.perNight : ''}</td>
                                                    <td>{packages.pricing && packages.pricing.Special ? packages.pricing.Special.weekly : ''}</td>
                                                    <td>{packages.pricing && packages.pricing.Special ? packages.pricing.Special.monthly : ''}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    )}

                                    <div className="container-fluid">
                                        <div className='paddingTable'>
                                            <p>{packages.Heading1}</p>
                                            <ul>
                                                {packages.features.map((feature, index) => (
                                                    <li key={index}>{feature}</li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="container-fluid">
                                        <div className='paddingTable'>
                                            <p>{packages.Heading2}</p>
                                            <ul>
                                                {packages.inclusions.map((inclusion, index) => (
                                                    <li key={index}>{inclusion}</li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                    <Link to={packages.link} className="book-cta d-flex place-center">
                                        {packages.ctaText}
                                    </Link>
                                </div>
                            ))
                        )}
                    </div>
                </section>
                <Location />
            </main>
        </>
    );
};

export default Packages;
