import React, { useState, useEffect } from "react";
import "../Progress-Button/ProgressButton.css";

const ProgressButton = () => {
    const [showProgress, setShowProgress] = useState(false);
    const [scrollValue, setScrollValue] = useState(0);
  
    useEffect(() => {
      const handleScroll = () => {
        const scrollTop = window.scrollY;
  
        setShowProgress(scrollTop > 150);
  
        const calcHeight = document.documentElement.scrollHeight - window.innerHeight;
        const value = Math.round((scrollTop * 100) / calcHeight);
  
        setScrollValue(value);
      };
  
      window.addEventListener("scroll", handleScroll);
  
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, []);
  
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    };
  
    return (
      <div
        id="progress"
        className={showProgress ? "show" : ""}
        onClick={scrollToTop}
        title="Up"
        style={{
          "--progress-color1": "#C6A068",
          "--progress-color2": "#c0c0ff",
          background: `conic-gradient(var(--progress-color1) ${scrollValue}%, var(--progress-color2) ${scrollValue}%)`
        }}
      >
        <span id="progress-value">↑</span>
      </div>
    );
  };
  
  export default ProgressButton;
  
