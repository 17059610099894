import React, { useEffect, useLayoutEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import ProgressButton from './components/Progress-Button/ProgressButton'
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import AboutUs from "./pages/About-Us/AboutUs";
import Packages from "./pages/Packages/Packages";
import Blog from "./pages/Blog/Blog";
import AddData from "./pages/AddData/AddData";
import About from './components/About/About';
import Terms from "./pages/Terms&Policy/Terms";
import Policy from "./pages/Terms&Policy/Policy";
function App() {

  return (
    <Router>
      <ScrollToTop />
      <Header />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/about" element={<AboutUs/>} />
        <Route exact path="/packages" element={<Packages />} />
        <Route exact path="/blog" element={<Blog />} />
        <Route exact path="/homelyretreats/admin" element={<AddData />} />
        <Route exact path="/subabout" element={<About />} />
        <Route exact path="/Terms" element={<Terms/>} />
        <Route exact path="/Policy" element={<Policy/>} />

      </Routes>
      <ProgressButton />
      <Footer />
    </Router>
  );
}

export default App;
