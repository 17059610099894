import React from 'react'
import "../About/About.css"
import Image1 from "../../assets/about-image.png"
import whatssapp from "../../assets/whatssapp.svg"
import { Link } from 'react-router-dom';

// <img src={whatssapp} alt="Homely Retreats" /> 

const About = () => {
    return (
        <>
            <section id="about-section">
                <div className="container">
                    <h4>About Us</h4>
                    <img src={Image1} alt="Homely Retreats" className='main-img' />
                    <div className="box">
                       <h2 className='top-text fs-5' style={{fontWeight: "400"}}>Welcome to Villa Cottage by Homely Retreats , an offbeat tourist destination nestled in the heart of nature's bounty. Our haven offers a unique experience with hutments spread across a vast expanse of apple orchards, natural flora and fauna, shrubs, and a tranquil water stream flowing gently downwards. </h2>
                        <h2 className='bottom-text fs-5' style={{fontWeight: "400"}}>
                            Immerse yourself in the beauty of the surroundings with carefully crafted landscaping that complements the natural setting. Embrace the serenity and connect with the beauty of the outdoors while enjoying the comfort of thoughtfully designed interiors and a range of amenities and services manned by trained and decent staff.
                        </h2>
                        
                    </div>
                    <h2 className='MoreText' style={{fontWeight: "400"}}> <h2 className='HeadingMoreText'>Villa Cottage Overview</h2><br/>

Far from the Madding Crowd - A Home Away from Home, in the Lap of a Mountainous Range. <br/><br/>

•	Escape the hustle and bustle of city life and find solace in our Villa Cottage, nestled amidst the serene beauty of a mountainous range. Here, you'll discover a haven where time slows down and nature's symphony takes center stage.<br/><br/>
•	Indulge in the tranquillity of our cozy huts, where every detail has been carefully curated to blend traditional charm with eco-friendly comfort. As you step onto the balconies, be greeted by breathtaking views of towering mountains, while lush green lawns adorned with vibrant flowers and aromatic plants beckon you to explore.<br/><br/>
•	Immerse yourself in the natural ecosystem that surrounds you - a gently flowing stream meandering through the compound, culminating in a tranquil fish pond where colorful fish gracefully glide. Listen to the melodious chirping of birds, the gentle hum of bees, and feel yourself being transported into a dreamlike state.<br/><br/>
•	In this secluded paradise, each hut is surrounded by its own private lawn, offering the perfect spot to unwind and reconnect with yourself. <br/><br/>
•	Step outside our huts and be greeted by a sprawling fruit orchard, alive with the colors and scents of the seasons. Handpick your favorite fruits from trees laden with nature's bounty, savoring the freshness and sweetness of each bite. This orchard oasis invites you to reconnect with the simple joys of nature, offering a taste of pure bliss with every harvest.<br/><br/>
</h2>
                    <Link to="/packages" className='d-flex place-center g-20'>Book a stay</Link>

                </div>
            </section>
        </>
    )
}

export default About