import React from 'react';
import { Link } from "react-router-dom";

const Policy = (props) => {
    return (
        <>
            <div className="TermsDiv" id="Policy" style={{padding:"0px", margin:"15vh 20px 20px 20px"}}  >
            
      <h1>Privacy Policy</h1>
      <p>Homely Retreats LLP (referred to as "Homely Retreats" or "we" or "our" is committed to protecting your privacy and ensuring the appropriate management of any personally identifiable information ("Personal Information") you share with us. This Privacy Policy applies to all visitors, users, and others (referred to as "you" or the "User") who access or use the website and services provided by Homely Retreats LLP.</p>
      
      <h2>Collection of Personal Information</h2>
      <p>Homely Retreats LLP collects personal information directly from you when you interact with us through our website, social media platforms, email, telephone, or in writing. This information may include:</p>
      <ul>
        <li>Contact Information: Name, phone number, address, email address.</li>
        <li>Demographic Information: Date of birth, occupation, country of origin.</li>
        <li>Account Information: If you create a user account, we may collect username, password, and contact details.</li>
      </ul>
      <p>We may also collect information automatically through cookies and log data when you visit our website. This may include details of your website usage, IP address, device information, and browsing history.</p>

      <h2>Purpose of Collection</h2>
      <p>We collect and use your personal information for the following purposes:</p>
      <ul>
        <li>To provide the services or information you have requested.</li>
        <li>To communicate with you and respond to your queries or feedback.</li>
        <li>For marketing and promotional purposes, including sending information about special promotions and offers.</li>
        <li>To maintain high-quality standards and improve our services.</li>
        <li>For internal business operations and analytics.</li>
        <li>To comply with legal obligations and protect our rights and safety.</li>
      </ul>

      <h2>Sharing of Information</h2>
      <p>Homely Retreats LLP may share your personal information with:</p>
      <ul>
        <li>Affiliates and business partners for the purposes outlined in this policy.</li>
        <li>Service providers who perform services on our behalf.</li>
        <li>Legal authorities in response to legal obligations or requests.</li>
      </ul>
      <p>We do not use or disclose information for purposes other than as mentioned in this policy, except with your consent or as required by law.</p>

      <h2>Security Measures</h2>
      <p>Homely Retreats LLP implements stringent security measures to protect your personal information from unauthorized access, disclosure, or alteration. We review and update our security practices regularly to ensure compliance with regulatory requirements.</p>

      <h2>User Rights</h2>
      <p>You have the right to access, rectify, erase, restrict processing, and request data portability of your personal information. You may also object to processing and withdraw consent for certain uses. Please contact us for assistance with exercising your rights.</p>

      <h2>Contact Information</h2>
      <p>If you have any questions, comments, or concerns about our Privacy Policy or data practices, please contact us:</p>
      <p>Homely Retreats LLP<br />
      Busserbugh Alesteng Link Road, Ganderbal, Jammu and Kashmir<br />
      Phone: +91-1943158376, Email: homelykashmir@gmail.com</p>

      <h2>Updates to the Privacy Policy</h2>
      <p>Homely Retreats LLP may periodically update this Privacy Policy. Your continued use of our services following any changes to this policy constitutes acceptance of those changes.</p>

      <h2>Miscellaneous</h2>
      <p>This Privacy Policy is governed by the laws of India, and any disputes shall be subject to the jurisdiction of courts in Ganderbal, Jammu and Kashmir.</p>
    
            </div>
        </>
    );
};

export default Policy;