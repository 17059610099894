import React, { useEffect } from "react";
import Banner from '../components/Banner/Banner'
import Offer from '../components/Offer/Offer'
import Location from '../components/Location/Location'
import About from '../components/About/About'
import BookChoice from '../components/Book-Choice/BookChoice'
import WhatsappBTN from '../components/Book-Choice/WhatsappBtn.jsx'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PeopleTalk from '../components/People-Talk/PeopleTalk'
const Home = () => {
    useEffect(() => {
        document.title = "Homely Retreats ";
      }, []);
    return (
        <>
            <Banner/>
            <Location
                bg = 'rgb(226, 220, 196)'
            />
            <Offer/>
            <WhatsappBTN/>
            <PeopleTalk/>
        </>
    )
}

export default Home