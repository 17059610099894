import React from 'react'
import DistanceLogo from "../../assets/distance.svg"
import CallLogo from "../../assets/call.svg"
import "../Location/Location.css"
const Location = (props) => {
    return (
        <section id="location-section" style={{ backgroundColor: props.bg }}>
            <div className="container d-flex-col place-center h-full">
                <h3>Homely Retreats</h3>
                <p>Location || <a href="http://homelyretreats.in/" target='_blank'> http://homelyretreats.in/ </a> </p>
                <div className="box d-flex g-30">
                    <div className="inner-box d-flex place-center ">
                        <img src={DistanceLogo} alt="Homely Retreats" />
                    </div>
                    <div className="inner-box d-flex place-center">
                        <a href="tel:9541786819">
                            <img src={CallLogo} alt="Homely Retreats" />
                        </a>
                    </div>
                </div>
            </div>
        </section>


    )
}

export default Location